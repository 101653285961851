<template>
  <v-container fluid align-center class="pt-15">
    <v-row class="mt-5 mx-5">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="3">
                  <span class="font-weight-bold">Filtros:</span>
                </v-col>
                <v-col cols="9" class="text--secondary" >
                  <v-fade-transition leave-absolute>
                    <span v-if="open"></span>
                    <v-row v-else no-gutters style="width: 100%">
                      <v-col cols="3">
                        <span class="font-weight-bold">Tipo de periodo: </span> <span style="color:#5271ff;">{{ typePeriod }}</span>
                      </v-col>
                      <v-col cols="3">
                        <span class="font-weight-bold">Periodo:</span> <span style="color:#5271ff;">{{ record.periodo }}</span>
                      </v-col>
                      <v-col cols="3">
                        <span class="font-weight-bold">Empresas:</span> <span style="color:#5271ff;">{{ record.empresas.length + ' seleccionadas' }}</span>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-col cols="3">
                  <v-select v-model="record.tipoPeriodo" label="Tipo de periodo" :items="catalogues.tipos_periodos" item-text="text" item-value="value"
                  prepend-icon="mdi-calendar-check" dense class="ml-5 mr-2" style="max-width: 290px;" :disabled="loading" @change="setDateRecord()"></v-select>
                </v-col>
                <v-col cols="3">
                  <template v-if="record.tipoPeriodo === 'mensual'">
                    <v-menu ref="menuRecord" v-model="record.menu" :close-on-content-click="false" :return-value.sync="record.periodo" 
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="record.periodo" label="Periodo" v-bind="attrs" dense v-on="on" prepend-icon="mdi-calendar" class="mx-2" style="max-width: 190px;" :disabled="loading" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="record.periodo" type="month" locale="es" style="background-color: #f1f1f1">
                        <v-spacer></v-spacer>
                        <v-btn text color="blue-grey" @click="record.menu = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="success" @click="$refs.menuRecord.save(record.periodo); getData()">
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </template>
                  <template v-else-if="record.tipoPeriodo === 'anual'">
                    <v-select v-model="record.periodo" label="Periodo" :items="catalogues.years" item-text="text" item-value="value"
                    prepend-icon="mdi-calendar" dense class="mx-2" style="max-width: 190px;" light hide-details :disabled="loading" @change="getData()"></v-select>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-select v-model="record.empresas" label="Empresas" multiple :items="catalogues.empresas" item-text="text" item-value="value"
                  prepend-icon="mdi-domain" dense class="ml-5 mr-2" style="max-width: 290px;" :disabled="loading"></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!errors.kpis && !loading" cols="12">
        <v-card min-height="150" min-width="300" class="pa-5" style="display: flex; align-items: center;"> 
          <v-row>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Circularidad
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;" class="pt-10">
                  {{ kpis.circularidad.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Generación de residuos
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(231 121 36); font-weight: bold;" class="pt-10">
                  {{ kpis.generacion.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Recuperación
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.recuperacion.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Residuos a relleno
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.residuos.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12">
        <v-card>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!errors.materiales_entrada && !loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Materiales de entrada</v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.materiales_entrada.data" :options="chartData.materiales_entrada.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.recuperacion_material && !loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Recuperación de material</v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.recuperacion_material.data" :options="chartData.recuperacion_material.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!errors.historico_materiales && !loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Histórico de materiales</v-card-title>
          <div class="container">
            <Bar :chartData="chartData.historico_materiales.data" :options="chartData.historico_materiales.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.circularityMeasures && !loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%"
        style="display: flex !important; flex-direction: column;">
          <v-card-title class="justify-center font-weight-bold">Medidas de circularidad</v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto;">
            <v-list-item-group v-model="selectCircularityMeasure" active-class="success--text">
              <template v-for="(item, idx) in circularityMeasures">
                <v-list-item :key="idx" @click="dialogs.circularityMeasures = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.equipo"></v-list-item-title>
                      <v-list-item-subtitle v-if="item.energia" class="text--primary" v-text="item.energia"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.sugerencia"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="success">
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= circularityMeasures.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>  
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card> 
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.circularityMeasures" persistent max-width="390">
      <v-card style="margin-top: 0; background-color: white;">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea comprometerse con la medida de circularidad?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue-grey" @click="cancelNotification()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="acceptCircularityMeasure()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment-timezone'
import Bar from '../Charts/StackedBar.js'
import Doughnut from '../Charts/Donut.js'
import _ from 'lodash'

export default {
  name: 'dashboardStandard',
  components: { Bar, Doughnut },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    errors: {
      materiales_entrada: false,
      recuperacion_material: false,
      historico_materiales: false,
      medidas_circularidad: false
    },
    msgError: '',
    catalogues: {
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      years: [],
      empresas: [
        { text: "Empresa 1", value: 'empresa_1' },
        { text: "Empresa 2", value: 'empresa_2' },
        { text: "Empresa 3", value: 'empresa_3' },
      ]
    },
    record: {
      loading: false,
      tipoPeriodo: 'mensual',
      periodo: '',
      menu: false,
      empresas: []
    },
    kpis: {
      circularidad: {
        valor: '31.36%'
      },
      generacion: {
        valor: '36.53%'
      },
      recuperacion: {
        valor: '99.7%'
      },
      residuos: {
        valor: '0.2%'
      },
    },
    chartData: {
      materiales_entrada: {
        data: null,
        options: null 
      },
      recuperacion_material: {
        data: null,
        options: null
      },
      historico_materiales: {
        data: null,
        options: null
      }
    },
    circularityMeasures: [],
    selectCircularityMeasure: null,
    dialogs: {
      circularityMeasures: false,
    }
  }),
  mounted() {
    this.setCatYears()
    this.setDateRecord()
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    typePeriod() {
      return _.capitalize(this.record.tipoPeriodo)
    },
  },
  watch: {
    company: function (val) {
      this.getData()
    },
  },
  methods: {
    setCatYears() {
      let year = parseInt(moment().format('YYYY'))
      for (let i = 0; i < 5; i++) {
        this.catalogues.years.unshift((year - i).toString())
      }
    },
    setDateRecord() {
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM')        
      }
      else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY')        
      }
      this.getData()
    },
    getKpis(){
    },
    getInputMaterials() {
      this.chartData.materiales_entrada.data = {
        labels: [ 'Material renovable', 'Material reciclado', 'Marerial lineal'],
        datasets: [
          {
            backgroundColor: [
              'rgba(112, 0, 224)',
              'rgba(9, 194, 236)',
              'rgba(240, 200, 8)',
            ],
            data: [23, 4, 73],
          },
        ]
      }
      this.chartData.materiales_entrada.options =  {
        responsive: true,
        maintainAspectRatio: false
      }
    },
    getRecoveryMaterial() {
      this.chartData.recuperacion_material.data = {
        labels: [ 'Residuos orgánicos recuperados', 'Residuos o relleno', 'Residuos inorgánicos recuperados'],
        datasets: [
          {
            backgroundColor: [
              'rgba(112, 0, 224)',
              'rgba(9, 194, 236)',
              'rgba(240, 200, 8)',
            ],
            data: [70, 20, 10],
          },
        ]
      }
      this.chartData.recuperacion_material.options =  {
        responsive: true,
        maintainAspectRatio: false
      }
    },
    getHistoryMaterials() {
      this.chartData.historico_materiales.data = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Material',
            backgroundColor: 'rgba(112, 0, 224)',
            data: [5, 6, 7, 8, 7, 6, 5, 6, 7, 5, 6, 7]
          },
          {
            label: 'Residuos',
            backgroundColor: 'rgba(9, 194, 236)',
            data: [2.5, 2.0, 2.8, 2.7, 3.5, 2.4, 1.7, 3.0, 2.8, 1.7, 3.0, 2.3]
          }
        ]
      }
      this.chartData.historico_materiales.options = {
        responsive: true,
        maintainAspectRatio: false
      }
    },
    getCircularityMeasures() {
      this.circularityMeasures = [
        {
          id: 1,
          equipo: 'Vidrio',
          energia: 'Material Reciclado',
          sugerencia: 'Reducción de emisiones por usar vidrio reciclado',
          ahorro: '98.23% de ahorro',
          comprometido: true
        },
        {
          id: 2,
          equipo: 'Textil',
          energia: 'Residuo ',
          sugerencia: 'Reducción de emisiones por reciclar textiles',
          ahorro: '98.3% de ahorro',
          comprometido: true
        },
        {
          id: 3,
          equipo: 'Orgánico',
          energia: 'Residuo ',
          sugerencia: 'Reducción de emisiones por compostaje',
          ahorro: '30% de ahorro',
          comprometido: true
        },
        {
          id: 4,
          equipo: 'Orgánico',
          energia: 'Residuo ',
          sugerencia: 'Reducción de emisiones por compostaje',
          ahorro: '50% de ahorro',
          comprometido: false
        },
        {
          id: 5,
          equipo: 'Orgánico',
          energia: 'Residuo ',
          sugerencia: 'Reducción de emisiones por composta de pastoreo',
          ahorro: '30% de ahorro',
          comprometido: false
        },
        {
          id: 6,
          equipo: 'Plástico',
          energia: 'Residuo ',
          sugerencia: 'Reducción de emisiones por reciclaje de plástico',
          ahorro: '25% de ahorro',
          comprometido: false
        }
      ]
    },
    getData() {
      this.errors.materiales_entrada = false
      this.errors.recuperacion_material = false
      this.getKpis()
      this.getInputMaterials()
      this.getRecoveryMaterial()
      this.getHistoryMaterials()
      this.getCircularityMeasures()
    },
    cancelNotification() {
      this.selectCircularityMeasure = null
      this.dialogs.circularityMeasures = false
    },
    acceptCircularityMeasure(id) {
      for (let i = 0; i < this.circularityMeasures.length; i++) {
        if (this.circularityMeasures[i].id == (this.selectCircularityMeasure + 1)) {
          this.circularityMeasures[i].comprometido = true
        }
      }
      this.selectCircularityMeasure = null
      this.dialogs.circularityMeasures = false
    },
  }
}
</script>

<style scoped>
  .text-normal {
    text-transform: none !important;
  }
  .small {
    max-width: 600px;
  }
  .container.fill-height {
    justify-content: space-around;
    margin: auto;
  }
  .v-card {
    margin-top: 10px;
    background-color: rgb(227 227 227 / 28%)
  }
  #card-cards {
    margin-top: 10px;
  }
  .card {
    display: flex;
    justify-content: space-around;
  }
  .v-picker__actions {
    background-color: #f1f1f1;
  }
</style>
