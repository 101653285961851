<template>
  <Public v-if="rol && isPublic()"/>
  <Standard v-else-if="rol"/>
</template>

<script>
import Public from '../components/EC/Public.vue'
import Standard from '../components/EC/Standard.vue'

export default {
  name: 'economiaCircular',
  components: { Public, Standard },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    rol: null,
  }),
  mounted() {
    this.check()
  },
  methods: {
    check() {
      this.rol = this.$session.get('rol')
    },
    isPublic() {
      if(this.rol.indexOf("publico") >= 0) {
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>
</style>
